// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-discover-1-js": () => import("./../../../src/pages/discover/1.js" /* webpackChunkName: "component---src-pages-discover-1-js" */),
  "component---src-pages-discover-2-js": () => import("./../../../src/pages/discover/2.js" /* webpackChunkName: "component---src-pages-discover-2-js" */),
  "component---src-pages-discover-3-5-js": () => import("./../../../src/pages/discover/3-5.js" /* webpackChunkName: "component---src-pages-discover-3-5-js" */),
  "component---src-pages-discover-3-js": () => import("./../../../src/pages/discover/3.js" /* webpackChunkName: "component---src-pages-discover-3-js" */),
  "component---src-pages-goals-js": () => import("./../../../src/pages/goals.js" /* webpackChunkName: "component---src-pages-goals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manage-1-js": () => import("./../../../src/pages/manage/1.js" /* webpackChunkName: "component---src-pages-manage-1-js" */),
  "component---src-pages-manage-2-5-js": () => import("./../../../src/pages/manage/2-5.js" /* webpackChunkName: "component---src-pages-manage-2-5-js" */),
  "component---src-pages-manage-2-js": () => import("./../../../src/pages/manage/2.js" /* webpackChunkName: "component---src-pages-manage-2-js" */),
  "component---src-pages-manage-3-5-js": () => import("./../../../src/pages/manage/3-5.js" /* webpackChunkName: "component---src-pages-manage-3-5-js" */),
  "component---src-pages-manage-3-js": () => import("./../../../src/pages/manage/3.js" /* webpackChunkName: "component---src-pages-manage-3-js" */),
  "component---src-pages-modernize-1-js": () => import("./../../../src/pages/modernize/1.js" /* webpackChunkName: "component---src-pages-modernize-1-js" */),
  "component---src-pages-modernize-2-5-js": () => import("./../../../src/pages/modernize/2-5.js" /* webpackChunkName: "component---src-pages-modernize-2-5-js" */),
  "component---src-pages-modernize-2-js": () => import("./../../../src/pages/modernize/2.js" /* webpackChunkName: "component---src-pages-modernize-2-js" */),
  "component---src-pages-modernize-3-js": () => import("./../../../src/pages/modernize/3.js" /* webpackChunkName: "component---src-pages-modernize-3-js" */)
}

